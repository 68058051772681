import { createRouter, createWebHashHistory } from "vue-router"

const routes = [
    {
        path: '/',
        name: "IndexPage",
        meta: {title: "米拉思培"},
        component: () => import('@/views/Index.vue')
    },
    {
        path: '/zhenti',
        name: "ZhenTi",
        meta: {title: "真题模拟"},
        component: () => import('@/views/ZhenTi.vue')
    },
    {
        path: '/jijing',
        name: "JiJing",
        meta: {title: "机经模拟"},
        component: () => import('@/views/JiJing.vue')
    },
    {
        path: '/lianxi',
        name: "LianXi",
        meta: {title: "题库练习"},
        component: () => import('@/views/LianXi.vue')
    },
    {
        path: '/part',
        name: "PartList",
        meta: {title: "题库练习"},
        component: () => import('@/views/Part.vue')
    },
    {
        path: '/answer',
        name: "AnswerDetail",
        meta: {title: "答题"},
        component: () => import('@/views/Answer.vue')
    },
    {
        path: '/answerEnd',
        name: "AnswerEnd",
        meta: {title: "答题结束"},
        component: () => import('@/views/AnswerEnd.vue')
    },
    {
        path: '/kecheng',
        name: "KeCheng",
        meta: {title: "课程"},
        component: () => import('@/views/KeCheng.vue')
    },
    {
        path: '/login',
        name: "LoginPage",
        meta: {title: "登录"},
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/register',
        name: "RegisterPage",
        meta: {title: "注册"},
        component: () => import('@/views/Register.vue')
    },
    {
        path: '/user/index',
        name: "UserIndex",
        meta: {title: "个人中心"},
        component: () => import('@/views/user/Index.vue')
    },
    {
        path: '/user/lianxi/record',
        name: "LianXiRecord",
        meta: {title: "练习记录"},
        component: () => import('@/views/user/LianXiRecord.vue')
    }
]
export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})


router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
    if(to.meta.title) {
        document.title = to.meta.title
    }
    if(to.path === '/' || to.path === '/login' || to.path === '/register') return next()
    // const token = window.localStorage.getItem("token")
    // if(!token) return next("/login")
    next()
})
 
export default router